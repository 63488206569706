import { usePageStore } from '@voix/store/pageStore'

export function useAtlantisHead() {
  const route = useRoute()

  let dissableGtm = false

  try {
    dissableGtm = Object.prototype.hasOwnProperty.call(route.query, 'dissable_gtm')
  }
  catch (e) { console.log('could  not check dissable_gtm', e) }

  // If the environment is not production, we don't want to load the GTM
  if (dissableGtm || process.env.NODE_ENV !== 'production') {
    return
  }

  const pageStore = usePageStore()
  const siteId = pageStore.currentPage?.site_id
  const config = useRuntimeConfig()

  useHead({
    meta: [
      { name: 'google-site-verification', content: 'On5W12ocT5q1YOGKvHwdxXtx88stk-oGO-45IDEWUDw' },
      { charset: 'utf-8' },
      { 'http-equiv': 'X-UA-Compatible', 'content': 'IE=edge' },
    ],
    script: [
      { type: 'text/javascript', innerHTML: `var mothershipApiKey = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNmI2ZWE0ZmY4NWE1YTA3ZDg0NmU3ZTU5Yjc4N2JlZDExZTg5NTBiYWI0ZjhiNzEyODM4MTk5MTc1MWI5MTA5N2RhN2ZmZDc3ODg1ODczOTkiLCJpYXQiOjE3MjU5NzE5MDUuMDI0NzY5LCJuYmYiOjE3MjU5NzE5MDUuMDI0Nzc0LCJleHAiOjE3NTc1MDc5MDUuMDIxNDkyLCJzdWIiOiI3Iiwic2NvcGVzIjpbImxvZ3MiXX0.sk0BOK_37kh0zMf-InImnt0HrVcyi_MoCQla_ABtEC8CDLWBtvw9__YelBvQBXy2CKHtbktniJHAcDyftJCPj62zlDAeKRGqQGZOvvV2Qs-7pfq4dI6V1giqX09XDKAzhU6LPK1dTGCYnxX5BUpTRq_cnvfAnWIt1Igj5Vyi9Sw";
          window.MothershipConfig = {
            apiKey: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNmI2ZWE0ZmY4NWE1YTA3ZDg0NmU3ZTU5Yjc4N2JlZDExZTg5NTBiYWI0ZjhiNzEyODM4MTk5MTc1MWI5MTA5N2RhN2ZmZDc3ODg1ODczOTkiLCJpYXQiOjE3MjU5NzE5MDUuMDI0NzY5LCJuYmYiOjE3MjU5NzE5MDUuMDI0Nzc0LCJleHAiOjE3NTc1MDc5MDUuMDIxNDkyLCJzdWIiOiI3Iiwic2NvcGVzIjpbImxvZ3MiXX0.sk0BOK_37kh0zMf-InImnt0HrVcyi_MoCQla_ABtEC8CDLWBtvw9__YelBvQBXy2CKHtbktniJHAcDyftJCPj62zlDAeKRGqQGZOvvV2Qs-7pfq4dI6V1giqX09XDKAzhU6LPK1dTGCYnxX5BUpTRq_cnvfAnWIt1Igj5Vyi9Sw"
          };` },
      { type: 'text/javascript', src: `https://www.atlantisbahamas.com/mothership-js.lib.js` },
      { type: 'text/javascript', src: `https://www.atlantisbahamas.com/mothership-analytics.lib.js` },
    ],
  })

  // We pass nochrome=true in to disable all types of things
  // to minimize the fluff that is loaded on the page. This
  // is used for things like the mobile app or iframes.
  if (route.query.nochrome) {
    if (typeof document !== 'undefined') {
      setTimeout(() => {
        try {
          document.querySelectorAll('a').forEach((el) => {
            el.setAttribute('target', '_top')
          })
        }
        catch (e) {
          console.error(e)
        }
      }, 3000)
    }
  }

  if (config.public.atlantisEnableGTM === 'true') {
    if (siteId === 1) {
      useHead({
        noscript: [
          {
            id: 'google-tm-noscript',
            innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-9KCL" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            body: true,
          },
        ],
        script: [
          {
            type: 'text/javascript',
            id: 'google-tm-head',
            innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-9KCL')`,
          },
          { type: 'text/javascript', innerHTML: 'var monetateT = new Date().getTime();' },
          { type: 'text/javascript', isrc: `//se.monetate.net/js/2/a-f67e2ee1/p/atlantisbahamas.com/entry.js` },
        ],
      })
    }

    if (siteId === 2) {
    //  <!-- OneTrust Cookies Consent Notice start for reefownersatlantis.com -->
      useHead({
        script: [
          { type: 'text/javascript', innerHTML: 'function OptanonWrapper() {}' },
          { 'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', 'type': 'text/javascript', 'data-domain-script': 'f21a57f4-1c67-4280-a845-9ee2736622a6' },
          {
            src: `https://www.googletagmanager.com/gtag/js?id=GTM-KNDXFLP`,
            async: true,
          },
        ],
      })
    }

    if (siteId === 3) {
    //  <!-- OneTrust Cookies Consent Notice start for careers.atlantisbahamas.com -->
      useHead({
        script: [
          { type: 'text/javascript', innerHTML: 'function OptanonWrapper() {}' },
          { 'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', 'type': 'text/javascript', 'data-domain-script': '9023922a-3af8-4937-bade-611bbbfd2e8a' },
        ],
      })
    }

    if (siteId === 4) {
    //  <!-- OneTrust Cookies Consent Notice start for myatlantisvacation.com -->
      useHead({
        script: [
          { type: 'text/javascript', innerHTML: 'function OptanonWrapper() {}' },
          { 'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', 'type': 'text/javascript', 'data-domain-script': 'da4929df-03ab-4255-b080-d7cef8d25b58' },
          {
            src: `https://www.googletagmanager.com/gtag/js?id=GTM-9KCL`,
            async: true,
          },
        ],
      })
    }

    if (siteId === 5) {
    //  <!-- OneTrust Cookies Consent Notice start for oceanclubgolfcourse.com -->
      useHead({
        script: [
          { type: 'text/javascript', innerHTML: 'function OptanonWrapper() {}' },
          { 'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', 'type': 'text/javascript', 'data-domain-script': 'a092ff9e-ec2e-42c6-a7cb-3b457434f15e' },
        ],
      })
    }
  }
}
